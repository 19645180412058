import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QRCodeComponent } from './qrCodeComponent';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        QRCodeComponent
    ],
    exports: [
        QRCodeComponent
    ]
})
export class QRCodeModule {
    static forRoot(): ModuleWithProviders<QRCodeModule> {
        return {
            ngModule: QRCodeModule
        };
    }
}
