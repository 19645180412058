import { Observable, Observer } from 'rxjs';
import { getImageInfo, IImageData, Logger } from '@myia/ngx-core';
import { QRCode } from 'jsqr';

// @dynamic
export class QRCodeReader {
    // input should be File object or image url
    static Decode(inputImage: File, logger: Logger): Observable<string|undefined> {
        return new Observable((observer: Observer<string|undefined>) => {
            import('jsqr').then(({default: jsQR}) => {
                // checkTransparency must be true to return canvas
                getImageInfo(inputImage, {maxWidth: 1024, maxHeight: 1024, minWidth: 400, createCanvas: true}).subscribe({
                  next: (imageData: IImageData) => {
                    if (imageData.canvas) {
                      const ctx = imageData.canvas.getContext('2d');
                      const pixelData = ctx?.getImageData(0, 0, imageData.canvas.width, imageData.canvas.height).data as Uint8ClampedArray;
                      try {
                        const result: QRCode | null = jsQR(pixelData, imageData.canvas.width, imageData.canvas.height);
                        if (result) {
                          logger.log(`Scanned QR code: ${result.data}`);
                          observer.next(result.data);
                          observer.complete();
                        } else {
                          logger.log(`QR code not found.`);
                          observer.next(undefined);
                          observer.complete();
                        }
                      } catch (err) {
                        observer.error(err);
                        observer.complete();
                      }
                    }
                  }, error: (err: any) => {
                    observer.error(err);
                    observer.complete();
                  }
                });
              });
        });
    }

}
