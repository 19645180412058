import { SVGDrawing } from './svgDrawing';
import { _isSupportCanvas, _getAndroid, _getTypeNumber } from './helperMethods';
import { TableDrawing } from './tableDrawing';
import { CanvasDrawing } from './canvasDrawing';
import { QRCodeModel } from './qrCodeModel';
import { QR_ERROR_CORRECT_LEVEL } from './qrErrorCorrectLevel';

/**
 * QRCode
 * @example
 * new QRCode(document.getElementById('test'), 'http://jindo.dev.naver.com/collie');
 *
 * @example
 * var oQRCode = new QRCode('test', {
 *    text : 'http://naver.com',
 *    width : 128,
 *    height : 128
 * });
 *
 * oQRCode.clear(); // Clear the QRCode.
 * oQRCode.makeCode('http://map.naver.com'); // Re-create the QRCode.
 *
 * @param el target element or 'id' attribute of element.
 * @param  vOption
 * @param  vOption.text QRCode link data
 * @param [vOption.width=256]
 * @param [vOption.height=256]
 * @param [vOption.border=2] default to 2 modules
 * @param [vOption.colorDark='#000000']
 * @param [vOption.colorLight='#ffffff']
 * @param [vOption.correctLevel=QRCode.CorrectLevel.H] [L|M|Q|H]
 */
export class QRCode {
  /**
   * @name QRCode.correctLevel
   */
  static correctLevel = QR_ERROR_CORRECT_LEVEL;

  private _htOption: any;
  private _android: number;
  private _el: any;
  private _oQRCode: any;
  private _oDrawing: any;

  constructor(el: any, vOption: any) {
    this._htOption = {
      width: 256,
      height: 256,
      border: 2,
      typeNumber: 4,
      colorDark: '#000000',
      colorLight: '#ffffff',
      correctLevel: QR_ERROR_CORRECT_LEVEL.H
    };

    if (typeof vOption === 'string') {
      vOption = {
        text: vOption
      };
    }

    // Overwrites options
    if (vOption) {
      for (const i in vOption) {
        if (vOption.hasOwnProperty(i)) {
          this._htOption[i] = vOption[i];
        }
      }
    }

    if (typeof el === 'string') {
      el = document.getElementById(el);
    }

    const useSVG = document.documentElement.tagName.toLowerCase() === 'svg';
    // Drawing in DOM by using Table tag
    const drawing = this._htOption.useSVG || useSVG ? SVGDrawing : !_isSupportCanvas() ? TableDrawing : CanvasDrawing;

    this._android = _getAndroid();
    this._el = el;
    this._oQRCode = null;
    this._oDrawing = new drawing(this._el, this._htOption);

    if (this._htOption.text) {
      this.makeCode(this._htOption.text);
    }
  }

  /**
   * Make the QRCode
   *
   * @param sText link data
   */
  makeCode(sText: string) {
    this._oQRCode = new QRCodeModel(_getTypeNumber(sText, this._htOption.correctLevel), this._htOption.correctLevel);
    this._oQRCode.addData(sText);
    this._oQRCode.make();
    this._el.title = sText;
    this._oDrawing.draw(this._oQRCode);
    this.makeImage();
  }

  /**
   * Make the Image from Canvas element
   * - It occurs automatically
   * - Android below 3 doesn't support Data-URI spec.
   *
   */
  makeImage() {
    if (typeof this._oDrawing.makeImage === 'function' && (!this._android || this._android >= 3)) {
      this._oDrawing.makeImage();
    }
  }

  /**
   * Clear the QRCode
   */
  clear() {
    this._oDrawing.clear();
  }


}
