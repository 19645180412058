import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { CoreUIModule } from '@myia/ngx-core-ui';
import { LocalizationModule } from '@myia/ngx-localization';
import { QRCodeModule } from '@myia/ngx-qr-code';
import { DtpUnitPipe } from './dtpUnitPipe';
import { PdfPreviewComponent } from './pdfPreviewComponent';
import { PdfTemplateComponent } from './pdfTemplateComponent';

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        CoreUIModule,
        LocalizationModule,
        QRCodeModule,
    ],
    declarations: [
        PdfPreviewComponent,
        PdfTemplateComponent,
        DtpUnitPipe
    ],
    exports: [
        PdfPreviewComponent,
        PdfTemplateComponent,
        DtpUnitPipe
    ],
    providers: []
})
export class PdfModule {}
