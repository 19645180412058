import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { catchError, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { forkJoin, Observable, Observer, of } from 'rxjs';

import { getBase64FromImageUrl } from '@myia/ngx-core';
import { QRCode } from '@myia/ngx-qr-code';

import {
  IPDFTemplate, IPDFTemplateBinding, IPDFTemplateElement, IPDFTemplateFont, IPDFTemplateGradient,
  IPDFTemplateImageElement, IPDFTemplatePage, IPDFTemplateQrCodeElement, IPDFTemplateRectElement,
  IPDFTemplateShapeElement, IPDFTemplateTextElement
} from './pdfTemplateEntities';
import { animate, AnimationBuilder, style } from '@angular/animations';

export enum PdfTemplateRenderState {
  LoadingLibraries,
  RenderingTemplate,
  Done
}

@Injectable({providedIn: 'root'})
export class PdfTemplateRenderService {

  private static _fontKit: any = null;
  private static _SVG: any = null;
  private _renderer: Renderer2;
  private _libsDownload?: Observable<void>;

  private _loadedFonts: Map<string, Observable<Buffer | undefined>>;
  private _loadedImages: Map<string, Observable<string | undefined>>;

  constructor(rendererFactory: RendererFactory2, private _animationBuilder: AnimationBuilder) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this._loadedFonts = new Map<string, Observable<Buffer | undefined>>();
    this._loadedImages = new Map<string, Observable<string | undefined>>();
  }

  renderTemplatePage(template: IPDFTemplate, pageNo: string, withCropPadding: boolean, withAnimation: boolean, isPreview: boolean, targetSvgEl?: HTMLElement, stateChanged?: (state: PdfTemplateRenderState) => void): Observable<any> {
    const templatePage = template.pages?.find(p => p.pageNo === pageNo) as IPDFTemplatePage;
    const loadFonts = template.fonts?.map(f => this.loadFontFile(f));

    // return forkJoin(
    //  [
    //   this.loadLibraries(stateChanged),
    //   ...loadFonts
    //   ]
    // ).pipe(
    //   catchError(_ => {
    //     console.error(`load failed!`);
    //     return of(null);
    //   }),
    //   tap(() => {
    //     console.log('Rendering template...');
    //     if (stateChanged) {
    //       stateChanged(PdfTemplateRenderState.RenderingTemplate);
    //     }
    //   }),
    //   map(_ => {
    //     return {};
    //   })
    // );
    //
    // return of(null);

    return forkJoin(
      [
        this.loadLibraries(stateChanged),
        ...(loadFonts ?? [])
      ]
    ).pipe(
      // load external data(fonts)
      tap(() => {
        console.log('Rendering template...');
        if (stateChanged) {
          stateChanged(PdfTemplateRenderState.RenderingTemplate);
        }
      }),
      // draw svg
      mergeMap(() => {
        const width = templatePage.width - (withCropPadding ? 0 : 2 * templatePage.cropSize);
        const height = templatePage.height - (withCropPadding ? 0 : 2 * templatePage.cropSize);

        if (targetSvgEl) {
          // destroy current SVG
          this.destroyCurrentSVG(targetSvgEl, undefined, withAnimation);
        }

        const svgEl: svgjs.Container = PdfTemplateRenderService._SVG(targetSvgEl || document.createElement('div'));
        svgEl.viewbox(withCropPadding ? 0 : templatePage.cropSize, withCropPadding ? 0 : templatePage.cropSize, width, height).width(width).height(height);

        const svgRootGroup = svgEl.group(); // create group as root of all template child elements to be able to crop whole template if needed (e.g. render without crop padding)
        const clip = svgEl.rect(width, height);
        if (!withCropPadding) {
          clip.move(templatePage.cropSize, templatePage.cropSize);
        }
        svgRootGroup.clipWith(clip);
        const sortedElements = templatePage.elements
          .filter(a => (!a.previewOnly || isPreview) && !a.disabled)
          .sort((a: IPDFTemplateElement, b: IPDFTemplateElement) => {
            return ((a.zIndex as number) > (b.zIndex as number)) ? 1 : (((b.zIndex as number) > (a.zIndex as number)) ? -1 : 0);
          });

        return forkJoin( // add elements to svg in order by zIndex
          ...sortedElements
            .map(templateEl => {
              const templateElementId = templateEl.id as string;
              const templateElementX = templateEl.x as number;
              const templateElementY = templateEl.y as number;
              const templateElementWidth = templateEl.width as number;
              const templateElementHeight = templateEl.height as number;
              const elementGroup = svgRootGroup.group();
              let svgElement;
              let svgElementLoadTask;

              switch (templateEl.type) {
                case 'text':
                  const textEl = templateEl as IPDFTemplateTextElement;
                  const textElementText = textEl.text as string;
                  const textElementColor = textEl.color as string;
                  const textElementAlign = textEl.align as string;
                  const textElementMaxHeight = textEl.maxHeight as number;
                  const textElementFont = textEl.font as string;
                  const textElementFontSize = textEl.fontSize as number;
                  const textElementHiddenOverflow = textEl.hiddenOverflow as boolean;
                  const textElementWrap = textEl.wrap as boolean;
                  const textElementLineHeight = textEl.lineHeight as number;
                  const textElementUnderline = textEl.underline as boolean;
                  const textElementAutoScaleDown = textEl.autoScaleDown as boolean;
                  const textElementEllipsis = textEl.ellipsis as boolean;
                  const textElementVerticalAlignment = textEl.verticalAlign as string;

                  let fontDef = template.fonts?.find((f: IPDFTemplateFont) => f.id === textElementFont && f.fontData);
                  if (!fontDef) {
                    fontDef = template.fonts?.find((f: IPDFTemplateFont) => f.fontData);
                    if (fontDef) {
                      console.log(`Font definition not found: ${textElementFont} - Substituted to: ${fontDef.id}`);
                    }
                  }
                  if (fontDef) {
                    svgElement = this.renderSvgText(elementGroup, textElementText, textElementAlign, templateElementWidth, textElementColor, fontDef.fontData, textElementFontSize, textElementHiddenOverflow, textElementWrap, textElementLineHeight, textElementUnderline, textElementAutoScaleDown, textElementEllipsis, templateElementHeight, textElementMaxHeight, textElementVerticalAlignment);
                  } else {
                    console.error(`Noo font definition not found!!!`);
                  }
                  break;
                case 'qrCode':
                  const qrCodeTemplateEl = templateEl as IPDFTemplateQrCodeElement;
                  const qrCodeElementCode = qrCodeTemplateEl.code as string;
                  const qrCodeElementLightColor = qrCodeTemplateEl.lightColor as string;
                  const qrCodeElementDarkColor = qrCodeTemplateEl.darkColor as string;
                  if (qrCodeElementCode) {
                    // draw QR code
                    const qrCodeSvgEl = this.drawQRCode(qrCodeElementCode, templateElementWidth, qrCodeElementLightColor, qrCodeElementDarkColor);
                    svgElement = PdfTemplateRenderService._SVG.adopt(qrCodeSvgEl);
                    elementGroup.add(svgElement);
                  } else {
                    console.log('QR code is not defined!');
                  }
                  break;
                case 'rect':
                  const rectTemplateEl = templateEl as IPDFTemplateRectElement;
                  const rectElementRx = rectTemplateEl.rx as number;
                  const rectElementRy = rectTemplateEl.ry as number;
                  // draw rect
                  const rectElement = elementGroup.rect(templateElementWidth, templateElementHeight);
                  if (rectElementRx || rectElementRy) {
                    rectElement.radius(rectElementRx || 0, rectElementRy || 0);
                  }
                  svgElement = rectElement;
                  this.stroke(svgElement, rectTemplateEl);
                  this.fill(svgElement, rectTemplateEl);
                  break;
                case 'ellipse':
                  const shapeEl = templateEl as IPDFTemplateShapeElement;
                  // draw rect
                  svgElement = elementGroup.ellipse(templateElementWidth, templateElementHeight);
                  this.stroke(svgElement, templateEl);
                  this.fill(svgElement, templateEl);
                  break;
                case 'image':
                  const imageEl = templateEl as IPDFTemplateImageElement;
                  const imageElementUrl = imageEl.url as string;
                  const imageElementDataUrl = imageEl.dataUrl as string;
                  const imageElementImageSizing = imageEl.imageSizing as string;
                  const imageElementBackground = imageEl.background as string;

                  svgElement = elementGroup.nested().width(templateElementWidth).height(templateElementHeight);
                  if (imageElementBackground) {
                    // draw background rect
                    svgElement.rect(templateElementWidth, templateElementHeight).fill(imageElementBackground);
                  }
                  const imgSvgWrap = svgElement;
                  svgElementLoadTask = ((imageElementDataUrl ? of(imageElementDataUrl) : this.loadImage(imageElementUrl)) as Observable<string | null>).pipe(
                    mergeMap((dataUrl: string | null) => {
                      if (!dataUrl) {
                        return of(null);
                      }
                      return new Observable((observer: Observer<svgjs.Element>) => {
                        const imgWr = imgSvgWrap.nested().size(templateElementWidth, templateElementHeight);
                        const imgSvg = imgWr.image(dataUrl).loaded(function(this: any, loader: any) {
                          let imgWidth;
                          let imgHeight;
                          if (imageElementImageSizing) {
                            const imgRatio = loader.width / loader.height;
                            switch (imageElementImageSizing) {
                              case 'cover':
                                imgWidth = templateElementWidth;
                                imgHeight = imgWidth / imgRatio;
                                this.move(-(imgWidth - templateElementWidth) / 2, -(imgHeight - templateElementHeight) / 2);
                                const clipCover = imgWr.rect(templateElementWidth, templateElementHeight);
                                this.clipWith(clipCover);
                                break;
                              case 'contain':
                                const widthRatio = loader.width / templateElementWidth;
                                const heightRatio = loader.height / templateElementHeight;
                                if (heightRatio > widthRatio) {
                                  // scale image to match the height, and let the width have the gaps
                                  imgHeight = templateElementHeight;
                                  imgWidth = imgHeight * imgRatio;
                                  this.move((templateElementWidth - imgWidth) / 2, 0);
                                } else {
                                  // scale image to match the width, and let the height have the gaps
                                  // scale image to match the height, and let the width have the gaps
                                  imgWidth = templateElementWidth;
                                  imgHeight = imgWidth / imgRatio;
                                  this.move(0, (templateElementHeight - imgHeight) / 2);
                                }
                                break;
                            }
                          } else {
                            if (!templateElementWidth || !templateElementHeight) {
                              imgWidth = loader.width;
                              imgHeight = loader.height;
                            } else {
                              imgWidth = templateElementWidth;
                              imgHeight = templateElementHeight;
                            }
                          }
                          this.size(imgWidth, imgHeight);
                          observer.next(imgSvgWrap);
                          observer.complete();
                        });
                        imgSvg.attr('preserveAspectRatio', 'none');
                      });
                    }));
                  break;
              }
              if (svgElement) {
                svgElement.attr('id', templateElementId);
                svgElement.attr('elOrder', templateEl.zIndex);
                if (!templateEl.relativeTo) { // elements with relative positioning will be moved to right position when all other elements are completed
                  svgElement.move(templateElementX, templateElementY);
                } else {
                  svgElement.opacity(0); // hide before setting proper position
                }
                if (templateEl.clipping) {
                  this.clipElement(svgElement, templateEl.clipping);
                }
              }

              return svgElementLoadTask || of(svgElement);
            })
        ).pipe(
          map((svgElements: Array<svgjs.Element>) => {
            console.log('All elements rendered.');
            // set location of element with relative position
            sortedElements.forEach((templateEl, index) => {
              if (templateEl.relativeTo) {
                const relativeToElementSvg = this.getRelativeToElement(templateEl.relativeTo, sortedElements, svgElements);
                const elementSvg = svgElements[index];
                if (relativeToElementSvg) {
                  const relativeToElementBottom = relativeToElementSvg.y() + relativeToElementSvg.height();
                  elementSvg.move(templateEl.x as number, relativeToElementBottom + (templateEl.y as number));
                } else {
                  console.log('Cannot calculate element position. Relative parent not found:' + templateEl.relativeTo);
                }
                elementSvg.opacity(1); // show after setting proper position
              }
            });
            if (stateChanged) {
              stateChanged(PdfTemplateRenderState.Done);
            }
            return svgEl;
          })
        );
      })
    );
  }

  clearCache() {
    this._loadedFonts.clear();
    this._loadedImages.clear();
  }

  private loadLibraries(stateChanged?: (state: PdfTemplateRenderState) => void): Observable<void> {
    if (!this._libsDownload) {
      this._libsDownload = new Observable((observer: Observer<void>) => {
        console.log('Loading pdf libraries...');
        if (stateChanged) {
          stateChanged(PdfTemplateRenderState.LoadingLibraries);
        }
        // @ts-ignore
        Promise.all([ import('svg.js'), import('fontkit')]).then(([{default: _SVG}, _fontKit]) => {
          // TTF font + SVG manipulation
          PdfTemplateRenderService._SVG = _SVG;
          PdfTemplateRenderService._fontKit = _fontKit;
          console.log('Pdf libraries loaded.');
          observer.next(undefined);
          observer.complete();
        });
      }).pipe(
        // cache -> return same result for all subscriptions
        shareReplay({refCount: true, bufferSize: 1})
      );
    }
    return this._libsDownload;
  }

  private renderSvgText(parentSvgEl: svgjs.Container, text: string, align: string, width: number, color: string, fontData: any, fontSize: number, hiddenOverflow: boolean, wrap: boolean, lineHeight: number, underline: boolean, autoScaleDown: boolean, ellipsis: boolean, minHeight: number, maxHeight: number, verticalAlignment: string): svgjs.Element {

    const debug = false; // render bounding box (for debugging purposes)

    const svgEl = parentSvgEl.nested();
    if (!text || !text.length) {
      // do not render undefined/empty text
      // draw empty rect as position placeholder for other fields (if used as relativeTo element)
      svgEl.rect(0, 0);
      return svgEl;
    }

    // open a font synchronously
    const font = PdfTemplateRenderService._fontKit.create(fontData);
    const scale = 1 / font.unitsPerEm * fontSize;
    const scaledWidth = (width || 0) / scale;
    const scaledMaxHeight = (maxHeight || 0) / scale;
    const scaledMinHeight = (minHeight || 0) / scale;
    if (!lineHeight) {
      lineHeight = 1.0; // default line height
    }

    const fontHeight = font.ascent - font.descent;
    // const fontLineSpacing = font.bbox.height - fontHeight;
    // const fontLineHeight = fontHeight + fontLineSpacing;

    const rootGroup = svgEl.group();

    // layout a string, using default shaping features.
    // returns a GlyphRun, describing glyphs and positions.
    const run = font.layout(text);

    let ellipsisWidth = 0;
    let runEllipsis: any;
    if (ellipsis) {
      // measure ellipsis size
      runEllipsis = font.layout('.');
      ellipsisWidth = runEllipsis.positions[0].xAdvance * 3;
    }

    // divide glyphs to groups by rows (if wrapped)
    let x = 0;
    let y = 0;
    let lastX = 0;
    let autoScale = 1.0;
    let breakPositions: Array<number> = [];
    let ellipsisPosition = -1;
    let scaleDownStep = 0.1;
    const scaleDownMin = 0.01;
    if (wrap || autoScaleDown) {
      while (true) {
        breakPositions = [];
        let lastSpaceIndex = -1;
        let lines = 1;
        let index;
        for (index = 0; index < run.glyphs.length; index++) {
          if (wrap && text[index] === ' ') {
            lastSpaceIndex = index;
          }
          const pos = run.positions[index];
          x += pos.xAdvance;
          if (x * autoScale > scaledWidth) {
            lastX = x;
            if (lastSpaceIndex !== -1 || autoScaleDown || ellipsis) {
              x = 0;
              if (wrap && lastSpaceIndex !== -1) {
                breakPositions.push(lastSpaceIndex);
                index = lastSpaceIndex;
                lastSpaceIndex = -1;
                lines++;
              } else {
                // scale down or ellipsis
                break;
              }
              if (maxHeight && lines * fontHeight * lineHeight * autoScale > scaledMaxHeight) {
                // scale down or ellipsis
                break;
              }
            }
          }
        }
        if (index === run.glyphs.length) {
          // finished
          break;
        }
        if (autoScaleDown) {
          autoScale -= scaleDownStep;
          if (autoScale <= scaleDownStep * 5) {
            scaleDownStep = scaleDownStep / 2;
            break;
          }
          if (autoScale <= scaleDownMin) {
            break;
          }
        } else {
          // ellipsis
          x = lastX;
          while (x * autoScale + ellipsisWidth > scaledWidth && index > 0) {
            const pos = run.positions[index];
            x -= pos.xAdvance;
            index--;
          }
          ellipsisPosition = index;
          break;
        }
      }
    }
    let textGroup = rootGroup.group().fill(color);
    (textGroup as any).lineWidth = 0;
    x = 0;
    y = 0;
    const textGroups = [textGroup];
    for (let index = 0; index < run.glyphs.length; index++) {
      const glyph = run.glyphs[index];
      if (breakPositions.indexOf(index) !== -1) {
        x = 0;
        y = 0;
        textGroup = rootGroup.group().fill(color);
        (textGroup as any).lineWidth = 0;
        textGroups.push(textGroup);
      } else {
        // get an SVG path for a glyph
        const path = glyph.path;
        const pos = run.positions[index];
        const glyphX = x + pos.xOffset;
        const glyphY = y + pos.yOffset;
        this.addSvgPath(textGroup, path.toSVG(), glyphX, glyphY);
        x += pos.xAdvance;
        y += pos.yAdvance;
        (textGroup as any).lineWidth = x;
      }
      if (index === ellipsisPosition) {
        const elGlyph = runEllipsis.glyphs[0];
        const pos = runEllipsis.positions[0];
        const path = elGlyph.path;
        for (let dotIndex = 0; dotIndex < 3; dotIndex++) {
          // get an SVG path for a ellipsis glyph
          const glyphX = x + pos.xOffset;
          const glyphY = y + pos.yOffset;
          this.addSvgPath(textGroup, path.toSVG(), glyphX, glyphY);
          x += pos.xAdvance;
          y += pos.yAdvance;
        }
        (textGroup as any).lineWidth = x;
        break;
      }
    }

    let maxLineWidth = 0;
    textGroups.forEach((tGroup, index) => {
      const lineWidth = (tGroup as any).lineWidth;
      let alignmentOffset = 0;
      if (width) {
        switch (align) {
          case 'right':
            alignmentOffset = scaledWidth / autoScale - lineWidth;
            break;
          case 'center':
            alignmentOffset = (scaledWidth / autoScale - lineWidth) / 2;
            break;
        }
      }
      if (maxLineWidth < lineWidth + alignmentOffset) {
        maxLineWidth = lineWidth + alignmentOffset;
      }
      // underline
      if (underline) {
        tGroup.rect(lineWidth, font.underlineThickness).move(0, font.underlinePosition - font.underlineThickness).fill(color);
      }
      // reflect by flipped baseline
      tGroup.scale(1, -1);
      // move to correct position after reflection
      tGroup.translate(alignmentOffset + run.bbox.minX, font.descent + font.ascent + fontHeight * lineHeight * index);
    });

    const textBlockHeight = textGroups.length * fontHeight * lineHeight;
    const svgElWidth = Math.min(Math.max(maxLineWidth * scale * autoScale, width || 0), width || Number.MAX_VALUE);
    const realHeight = textBlockHeight * scale * autoScale;
    const svgElHeight = Math.min(Math.max(minHeight, realHeight), maxHeight || Number.MAX_VALUE);
    svgEl.width(svgElWidth).height(svgElHeight);

    const viewBoxWidth = Math.min(Math.max(maxLineWidth, scaledWidth / autoScale), scaledWidth / autoScale || Number.MAX_VALUE);
    const viewBoxHeight = Math.min(Math.max(scaledMinHeight / autoScale, textBlockHeight), (scaledMaxHeight / autoScale) || Number.MAX_VALUE);
    const viewBoxLeft = run.bbox.minX;
    const viewBoxTop = font.descent;
    svgEl.viewbox(viewBoxLeft, viewBoxTop, viewBoxWidth, viewBoxHeight);
    if (debug) {
      // debug - fill background to see occupied space
      const bgRectDebug = svgEl.rect();
      bgRectDebug.width(viewBoxWidth).height(viewBoxHeight);
      const debugRectColor = '#00f';
      bgRectDebug.move(viewBoxLeft, viewBoxTop).attr({
        class: 'debugElBlock',
        fill: debugRectColor,
        'fill-opacity': 0.3,
        stroke: debugRectColor,
        'stroke-width': 0.5 / scale / autoScale
      });
    }


    if (hiddenOverflow) {
      const clipRect = rootGroup.rect(viewBoxWidth, viewBoxHeight); // clip is moved to root SVG but dimensions must be same as text svg viewBox
      clipRect.move(viewBoxLeft, viewBoxTop);
      rootGroup.clipWith(clipRect);
    }

    if (verticalAlignment) {
      let verticalAlignmentOffset = 0;
      if (minHeight && realHeight < minHeight) {
        switch (verticalAlignment) {
          case 'middle':
            verticalAlignmentOffset = (minHeight - realHeight) / 2;
            break;
          case 'bottom':
            verticalAlignmentOffset = (minHeight - realHeight);
            break;
        }
      }
      if (verticalAlignmentOffset) {
        rootGroup.translate(0, verticalAlignmentOffset / scale / autoScale);
      }
    }

    return svgEl;

  }

  private addSvgPath(group: svgjs.G, pathData: string, x: number, y: number): svgjs.Path {
    const path = group.path(pathData);
    path.transform({x, y});
    return path;
  }

  private loadFontFile(font: IPDFTemplateFont): Observable<string | null> {
    if (!this._loadedFonts.has(font.url)) {
      this._loadedFonts.set(font.url, new Observable((observer: Observer<Buffer | undefined>) => {
          console.log(`Loading font ${font.id}: ${font.url}`);
          const xhr = new XMLHttpRequest();
          xhr.open('GET', font.url, true);
          // Ask for the result as an ArrayBuffer.
          xhr.responseType = 'arraybuffer';
          xhr.onerror = () => {
            console.log(`Font ${font.id} failed: ${xhr.status}`);
            observer.error(xhr.status);
            observer.complete();
          };
          xhr.onload = () => {
            // Obtain a blob: URL for the image data.
            console.log(`Font ${font.id} loaded.`);
            observer.next(Buffer.from(xhr.response));
            observer.complete();
          };
          xhr.send();
        }).pipe(
        // cache -> return same result for all subscriptions
        shareReplay({refCount: true, bufferSize: 1}),
        catchError(_ => {
          console.error(`load failed!`);
          return of(undefined);
        }),
        )
      );
    }
    return this._loadedFonts.get(font.url)?.pipe(
      map(fontData => {
        font.fontData = fontData;
        return font.id;
      })
    ) ?? of(null);
  }

  private loadImage(url: string | null) {
    if (!url) {
      return of(null);
    }
    if (!this._loadedImages.has(url)) {
      this._loadedImages.set(url, getBase64FromImageUrl(url).pipe(
        catchError(_ => {
          console.error(`PDF Template image load failed: ${url}`);
          return of(undefined);
        }),
        // cache -> return same result for all subscriptions
        shareReplay({ refCount: true, bufferSize: 1 }),
      ));
    }
    return this._loadedImages.get(url);
  }

  private drawQRCode(code: string, size: number, colorLight: string, colorDark: string): Element {
    const inputElement = this._renderer.createElement('div');
    this._renderer.setStyle(inputElement, 'opacity', 0);
    this._renderer.setStyle(inputElement, 'position', 'absolute');
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    new QRCode(inputElement, {
      text: code,
      width: size,
      height: size,
      colorDark,
      colorLight,
      useSVG: true,
      correctLevel: QRCode.correctLevel.M,
      avoidRefs: true,
      fixedSize: true,
      withOverflow: true
    });
    const svgCodeEl = inputElement.firstChild;
    inputElement.innerHTML = '';
    return svgCodeEl;
  }

  private stroke(rectEl: svgjs.Element, templateElement: IPDFTemplateShapeElement) {
    if (templateElement.stroke) {
      rectEl.stroke({color: templateElement.stroke as string, width: templateElement.strokeWidth ? (templateElement.strokeWidth as number) : 0});
    }
  }

  private destroyCurrentSVG(svgWrapper: HTMLElement, currentSvgEl: SVGElement | undefined, withAnimation?: boolean) {
    // destroy current Canvas
    if (withAnimation) {
      let currentSVG: SVGElement | undefined;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < svgWrapper.children.length; i++) {
        const svgEl = svgWrapper.children[i];
        if (!svgEl.classList.contains('removing') && svgEl !== currentSvgEl) {
          currentSVG = svgEl as SVGElement;
          break;
        }
      }
      if (currentSVG) {
        console.log('Removing PDf template SVG with animation.');
        currentSVG.classList.add('removing');
        const animationFactory = this._animationBuilder.build([
          style({opacity: 1, zIndex: 2}),
          animate(200, style({opacity: 0}))
        ]);
        const animPlayer = animationFactory.create(currentSVG);
        animPlayer.onDone(() => {
          console.log('PDf template SVG animation completed.');
          if (currentSVG?.parentElement) {
            currentSVG.parentElement.removeChild(currentSVG);
            console.log('PDf template SVG removed.');
          }
          animPlayer.destroy();
        });
        animPlayer.play();
      } else {
        console.log('No PDf Preview canvas found.');
      }
    } else {
      const children = Array.from(svgWrapper.children);
      for (const svgEl of children) {
        if (svgEl !== currentSvgEl) {
          svgWrapper.removeChild(svgEl);
          console.log('PDf template SVG removed (no anim).');
        }
      }
    }
  }

  private clipElement(svgElement: svgjs.Element, clipping: IPDFTemplateShapeElement) {
    const templateElementX = clipping.x as number;
    const templateElementY = clipping.y as number;
    const templateElementWidth = clipping.width as number;
    const templateElementHeight = clipping.height as number;
    const parent = (svgElement.parent() as svgjs.Container);
    let clipShape;
    switch (clipping.type) {
      case 'rect':
        const rectTemplateEl = clipping as IPDFTemplateRectElement;
        const rectElementRx = rectTemplateEl.rx as number;
        const rectElementRy = rectTemplateEl.ry as number;
        // draw rect
        const rectElement = parent.rect(templateElementWidth, templateElementHeight);
        if (rectElementRx || rectElementRy) {
          rectElement.radius(rectElementRx || 0, rectElementRy || 0);
        }
        clipShape = rectElement;
        break;
      case 'ellipse':
        // draw rect
        clipShape = parent.ellipse(templateElementWidth, templateElementHeight);
        break;
    }
    if (clipShape) {
      clipShape.move(templateElementX, templateElementY);
      parent.clipWith(clipShape);
    }
  }

  private fill(svgElement: svgjs.Element, templateShapeEl: IPDFTemplateShapeElement) {
    if (templateShapeEl.fill) {
      let shapeElementFill: any;
      if (typeof templateShapeEl.fill === 'string') {
        shapeElementFill = templateShapeEl.fill as string;
      } else {
        // gradient
        const svg = (svgElement.doc() as svgjs.Container);
        const gradientFill = templateShapeEl.fill as IPDFTemplateGradient;
        shapeElementFill = svg.gradient(gradientFill.type).from(gradientFill.fromX, gradientFill.fromY).to(gradientFill.toX, gradientFill.toY);
        gradientFill.stops.forEach(stop => {
          shapeElementFill.at({offset: stop.offset, color: stop.color, opacity: stop.opacity});
        });
      }
      if (shapeElementFill) {
        svgElement.fill(shapeElementFill);
      } else {
        console.log('Unknown fill value detected!');
      }
    }
  }

  private getRelativeToElement(relativeTo: string | IPDFTemplateBinding | undefined, templateElements: IPDFTemplateElement[], svgElements: Array<svgjs.Element>): svgjs.Element | undefined {
    const relativeToElementIndex = templateElements.findIndex(e => e.id === relativeTo);
    if (relativeToElementIndex >= 0) {
      const relativeToElementSvg = svgElements[relativeToElementIndex];
      if (relativeToElementSvg) {
        return relativeToElementSvg;
      }
      if (templateElements[relativeToElementIndex].relativeTo) {
        return this.getRelativeToElement(templateElements[relativeToElementIndex].relativeTo, templateElements, svgElements);
      }
    }
    return undefined;
  }
}
