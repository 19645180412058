import { DTPUnits, IDTPUnit } from './pdfEntities';

// @dynamic
export class DTPUnitHelper {
    static convertToUnit(value: number, valueUnitId: string, targetUnitId: string): number {
        if (valueUnitId !== targetUnitId) {
            const valueUnit = DTPUnitHelper.getDtpUnitById(valueUnitId);
            const targetUnit = DTPUnitHelper.getDtpUnitById(targetUnitId);
            if (valueUnit && targetUnit) {
                const valueInPt = value * valueUnit.ptConversionFactor;
                return valueInPt / targetUnit.ptConversionFactor;
            }
        }
        return value;
    }

    private static getDtpUnitById(unitId: string): IDTPUnit {
        return DTPUnits.find(u => u.id === unitId) as IDTPUnit;
    }
}
