import {
  ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2
} from '@angular/core';

import { QRCode } from './qrCode';
import { Observable, Observer, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger, NgChanges } from '@myia/ngx-core';

function isValidQrCodeText(data: string) {
  return !(typeof data === 'undefined' || data === '');
}

@Component({
  selector: 'qr-code',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ''
})
export class QRCodeComponent implements OnChanges, OnInit {
  @Input() data = '';
  @Input() size = 256;
  @Input() level = 'M';
  @Input() colorDark = '#000000';
  @Input() colorLight = '#ffffff';
  @Input() useSvg = false;

  @HostBinding('class') hostClasses = 'qrCode';

  private qrCode: any;

  constructor(private _el: ElementRef, private _renderer: Renderer2, private _logger: Logger) {
  }

  ngOnInit() {
    try {
      if (!isValidQrCodeText(this.data)) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('Empty QR Code data');
      }
      this.qrCode = new QRCode(this._el.nativeElement, {
        text: this.data,
        width: this.size,
        height: this.size,
        colorDark: this.colorDark,
        colorLight: this.colorLight,
        useSVG: this.useSvg,
        correctLevel: (QRCode.correctLevel as any)[this.level.toString()]
      });
    } catch (e: any) {
      this._logger.error('Error generating QR Code: ' + e.message);
    }
  }


  ngOnChanges(changes: NgChanges<QRCodeComponent>) {
    if (!this.qrCode) {
      return;
    }
    const data = changes.data;
    if (data && isValidQrCodeText(data.currentValue)) {
      this.qrCode.clear();
      this.qrCode.makeCode(data.currentValue);
    }
  }

  exportSVG(size: number): Observable<string> {
    const inputElement = this._renderer.createElement('div');
    this._renderer.setStyle(inputElement, 'opacity', 0);
    this._renderer.setStyle(inputElement, 'position', 'absolute');
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    new QRCode(inputElement, {
      text: this.data,
      width: size,
      height: size,
      colorDark: this.colorDark,
      colorLight: this.colorLight,
      useSVG: true,
      correctLevel: (QRCode.correctLevel as any)[this.level.toString()]
    });
    const svgCode = inputElement.innerHTML;
    inputElement.innerHTML = '';
    return of(svgCode);
  }

  exportPNG(size: number): Observable<Blob> {
    const inputElement = this._renderer.createElement('div');
    this._renderer.setStyle(inputElement, 'opacity', 0);
    this._renderer.setStyle(inputElement, 'position', 'absolute');
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    new QRCode(inputElement, {
      text: this.data,
      width: size,
      height: size,
      colorDark: this.colorDark,
      colorLight: this.colorLight,
      useSVG: false,
      correctLevel: (QRCode.correctLevel as any)[this.level.toString()]
    });
    const canvas = inputElement.querySelector('canvas');
    return this.getCanvasBlob(canvas, 'image/png').pipe(
      map(b => {
        inputElement.innerHTML = '';
        return b;
      })
    );
  }

  private getCanvasBlob(canvas: any, imgType = 'image/jpeg', quality = 80): Observable<Blob> {
    return new Observable((observer: Observer<any>) => {
      if (!HTMLCanvasElement.prototype.toBlob) {
        const binStr = atob(canvas.toDataURL(imgType, quality).split(',')[1]);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }

        observer.next(new Blob([arr], {type: imgType}));
        observer.complete();
      } else {
        canvas.toBlob((blob: any) => {
          observer.next(blob);
          observer.complete();
        }, imgType, quality);
      }
    });
  }
}
