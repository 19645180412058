import { _getAndroid, _onMakeImage, _safeSetDataURI } from './helperMethods';

/**
 * Drawing QRCode by using canvas
 *
 * @param el
 * @param htOption QRCode Options
 */
export class CanvasDrawing {
    private _bIsPainted: boolean;
    private _android: number;
    private _htOption: any;
    private _elCanvas: HTMLCanvasElement;
    private _el: Element;
    private _oContext: CanvasRenderingContext2D | null;
    private _elImage: HTMLImageElement;
    private _bSupportDataURI?: boolean;

    constructor(el: any, htOption: any) {
        this._bIsPainted = false;
        this._android = _getAndroid();

        this._htOption = htOption;
        this._elCanvas = document.createElement('canvas');
        this._elCanvas.width = htOption.width;
        this._elCanvas.height = htOption.height;
        el.appendChild(this._elCanvas);
        this._el = el;
        this._oContext = this._elCanvas.getContext('2d');
        this._bIsPainted = false;
        this._elImage = document.createElement('img');
        this._elImage.alt = 'Scan me!';
        this._elImage.style.display = 'none';
        this._el.appendChild(this._elImage);
        this._bSupportDataURI = undefined;

        // Android 2.1 bug workaround
        // http://code.google.com/p/android/issues/detail?id=5141
        if (this._android && this._android <= 2.1) {
            const factor = 1 / window.devicePixelRatio;
            const drawImage = CanvasRenderingContext2D.prototype.drawImage;
            CanvasRenderingContext2D.prototype.drawImage = function(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, sx?: number, sy?: number, sw?: number, sh?: number, dx?: number, dy?: number, dw?: number, dh?: number) {
                if (('nodeName' in image) && /img/i.test(image.nodeName)) {
                    for (let i = arguments.length - 1; i >= 1; i--) {
                        arguments[i] = arguments[i] * factor;
                    }
                } else if (typeof dw === 'undefined') {
                    arguments[1] *= factor;
                    arguments[2] *= factor;
                    arguments[3] *= factor;
                    arguments[4] *= factor;
                }

                drawImage.apply(this, arguments as any);
            };
        }

    }

    /**
     * Draw the QRCode
     *
     * @param oQRCode
     */
    draw(oQRCode: any) {
        const _elImage = this._elImage;
        const _oContext = this._oContext;
        const _htOption = this._htOption;

        const nCount = oQRCode.getModuleCount();
        const nWidth = _htOption.width / (nCount + 2 * _htOption.border);
        const nHeight = _htOption.height / (nCount + 2 * _htOption.border);
        const nBorderWidth = _htOption.border * nWidth;
        const nBorderHeight = _htOption.border * nHeight;
        const nRoundedWidth = Math.round(nWidth);
        const nRoundedHeight = Math.round(nHeight);

        _elImage.style.display = 'none';
        this.clear();

        if (_oContext) {
          // Fill quiet zone with light color
          _oContext.lineWidth = 0;
          _oContext.fillStyle = _htOption.colorLight;
          _oContext.fillRect(0, 0, _htOption.width, _htOption.height);

          for (let row = 0; row < nCount; row++) {
            for (let col = 0; col < nCount; col++) {
              const bIsDark = oQRCode.isDark(row, col);
              const nLeft = col * nWidth;
              const nTop = row * nHeight;
              _oContext.strokeStyle = bIsDark ? _htOption.colorDark : _htOption.colorLight;
              _oContext.lineWidth = 1;
              _oContext.fillStyle = bIsDark ? _htOption.colorDark : _htOption.colorLight;
              _oContext.fillRect(nLeft + nBorderWidth, nTop + nBorderHeight, nWidth, nHeight);

              _oContext.strokeRect(
                Math.floor(nLeft + nBorderWidth) + 0.5,
                Math.floor(nTop + nBorderHeight) + 0.5,
                nRoundedWidth,
                nRoundedHeight
              );

              _oContext.strokeRect(
                Math.ceil(nLeft + nBorderWidth) - 0.5,
                Math.ceil(nTop + nBorderHeight) - 0.5,
                nRoundedWidth,
                nRoundedHeight
              );
            }
          }
          _oContext.lineWidth = 1;
          _oContext.strokeStyle = _htOption.colorLight;
          _oContext.fillStyle = _htOption.colorLight;
          _oContext.fillRect(_htOption.width - nBorderWidth - 0.5, 0, nBorderWidth, _htOption.height);
          _oContext.fillRect(0, _htOption.height - nBorderHeight - 0.5, _htOption.width, nBorderHeight);
          _oContext.strokeRect(_htOption.width - nBorderWidth, 0, nBorderWidth, _htOption.height);
          _oContext.strokeRect(0, _htOption.height - nBorderHeight, _htOption.width, nBorderHeight);
        }

        this._bIsPainted = true;
    }

    /**
     * Make the image from Canvas if the browser supports Data URI.
     */
    makeImage() {
        if (this._bIsPainted) {
            _safeSetDataURI.call(this, _onMakeImage, null);
        }
    }

    /**
     * Return whether the QRCode is painted or not
     *
     * @return
     */
    isPainted() {
        return this._bIsPainted;
    }

    /**
     * Clear the QRCode
     */
    clear() {
        this._oContext?.clearRect(0, 0, this._elCanvas.width, this._elCanvas.height);
        this._bIsPainted = false;
    }

    /**
     * @param nNumber
     */
    round(nNumber: number) {
        if (!nNumber) {
            return nNumber;
        }

        return Math.floor(nNumber * 1000) / 1000;
    }
}
