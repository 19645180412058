import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Logger } from '@myia/ngx-core';
import { forkJoin, Observable, Observer, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap, throttleTime } from 'rxjs/operators';
import svgjs from 'svg.js';
import { IPDFTemplate, IPDFTemplatePage } from './pdfTemplateEntities';
import { PdfTemplateRenderService, PdfTemplateRenderState } from './pdfTemplateRenderService';

@Component({
  selector: 'pdf-template',
  styleUrls: ['./pdfTemplateComponent.component.scss'],
  template: `
    <div class="loading" *ngIf="showState && (loadingLibs || generating || isWaitingForTemplate)">
      <progress-indicator-circle indicatorClass="small"></progress-indicator-circle>
      <span>{{(loadingLibs ? 'Loading' : this.processingText)|trans}}</span>
    </div>
    <div class="svgContainer" #svgContainer [ngClass]="{show: !isWaitingForTemplate && (!showState || !generating)}" (mouseWheel)="onMouseWheel($event)" (mousePan)="onMousePan($event)">
      <div class="svgInnerContainer" #svgInnerContainer></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfTemplateComponent implements AfterViewInit, OnDestroy, OnChanges {

  @HostBinding('class') hostClasses = 'pdfTemplate';
  @ViewChild('svgContainer', {static: true}) svgContainerEl?: ElementRef;
  @ViewChild('svgInnerContainer', {static: true}) svgInnerContainerEl?: ElementRef;

  @Input() showState = true;
  @Input() processingText = '';

  @Input() zoom = 1.0; // 1.0 = 100%
  @Input() offset: { x: number, y: number } = {x: 0, y: 0};

  @Input() maxZoom = 5;
  @Input() minZoom = 1;

  @Input() pageNo?: string; // show single page only (for templates with multiple pages)
  @Input() pageViewMargin = 0;

  @Output() stateChange = new EventEmitter<PdfTemplateRenderState>();
  @Output() zoomChange = new EventEmitter<number>();
  @Output() offsetChange = new EventEmitter<{ x: number, y: number }>();

  @Input() template?: IPDFTemplate;

  @HostBinding('class.updating')
  public get isUpdating(): boolean {
    return this.generating && !this.showState;
  }

  @HostBinding('class.loading')
  loadingLibs = true;
  @HostBinding('class.generating')
  generating = false;
  @HostBinding('class.waiting')
  isWaitingForTemplate = false;

  private _viewInitializing? = new Subject<void>();
  private _isDirty = false;
  private _currentJob?: Observable<any>;
  private _mouseWheelObserver?: Observer<void>;
  private _mouseWheelSubscription?: Subscription;
  private _svgEls?: Array<SVGElement>;

  constructor(private _changeDetectorRef: ChangeDetectorRef, private _logger: Logger, private _pdfTemplateRenderService: PdfTemplateRenderService) {
  }

  ngAfterViewInit() {
    this._viewInitializing?.next();
    this._viewInitializing?.complete();
    this._viewInitializing = undefined;
    this.setDirty();
  }

  ngOnDestroy() {
    if (this._mouseWheelSubscription) {
      this._mouseWheelSubscription.unsubscribe();
    }
    this._mouseWheelObserver = undefined;
    this._svgEls = undefined;
    this._currentJob = undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._viewInitializing) {
      let isDirty = false;
      let updateView = false;
      // eslint-disable-next-line guard-for-in
      for (const changedPropName in changes) {
        switch (changedPropName) {
          case 'template':
            isDirty = true;
            break;
          case 'zoom':
            updateView = true;
            break;
          case 'offset':
            this.onMousePan(this.offset);
            break;
        }
        if (isDirty) {
          break;
        }
      }
      if (isDirty) {
        this.setDirty();
        return;
      }
      if (updateView) {
        this.updateView();
      }
    }
  }


  onMouseWheel(e: any) {
    if (!this._mouseWheelObserver) {
      this._mouseWheelSubscription =
        new Observable((observer: Observer<any>) => {
          this._mouseWheelObserver = observer;
        }).pipe(
          throttleTime(50, undefined, {
            leading: true,
            trailing: true
          }) // wait 50ms after the last event before emitting last event
        ).subscribe((delta: number) => {
          let newZoom = this.zoom + (delta > 0 ? 0.1 : -0.1);
          if (newZoom > this.maxZoom) {
            newZoom = this.maxZoom;
          }
          if (newZoom < this.minZoom) {
            newZoom = this.minZoom;
          }
          this.zoom = newZoom;
          this.updateView();
          this.zoomChange.emit(newZoom);
        });
    }

    this._mouseWheelObserver?.next(e);
  }

  onMousePan($event: { x: number; y: number }) {
    this.offset.x += $event.x;
    this.offset.y += $event.y;
    this.offsetChange.emit(this.offset);
    // console.log(`${this.offsetX}, ${this.offsetY}`);
    if (this.svgContainerEl) {
      this.svgContainerEl.nativeElement.style.transform = `translate(${this.offset.x}px, ${this.offset.y}px)`;
    }
  }

  private drawTemplate(template: IPDFTemplate): Observable<Array<any>> {
    return ((this._viewInitializing ? this._viewInitializing : of(undefined)) as Observable<void>).pipe(
      // render template pages
      mergeMap(() => {
        // remove all unused elements
        const existingPageContainers: Array<HTMLElement> = Array.from(this.svgInnerContainerEl?.nativeElement.getElementsByClassName('templatePage'));
        existingPageContainers.forEach((existingPageContainer: HTMLElement) => {
          const pId = existingPageContainer.getAttribute('pageNo');
          if (!template.pages?.find(p => p.pageNo === pId)) {
            existingPageContainer.parentNode?.removeChild(existingPageContainer);
          }
        });
        const pageTasks = (this.pageNo ? [template.pages?.find(p => p.pageNo === this.pageNo)] : template?.pages ?? []).map((page: IPDFTemplatePage| undefined) => {
          const pageNo = page?.pageNo as string;
          const svgInnerContainerEl = this.svgInnerContainerEl?.nativeElement as HTMLElement;
          let svgPageContainerEl = Array.from<Element>(svgInnerContainerEl.children).find(e => e.getAttribute('id') === `page_${pageNo}`) as HTMLElement;
          if (!svgPageContainerEl) {
            svgPageContainerEl = document.createElement('div');
            svgPageContainerEl.setAttribute('pageNo', pageNo);
            svgPageContainerEl.setAttribute('id', `page_${pageNo}`);
            svgPageContainerEl.classList.add('templatePage');
            this.svgInnerContainerEl?.nativeElement.appendChild(svgPageContainerEl);
          }
          return this._pdfTemplateRenderService.renderTemplatePage(template, pageNo, false, !this.showState, true, svgPageContainerEl, renderState => {
            if (!this.generating) {
              switch (renderState) {
                case PdfTemplateRenderState.LoadingLibraries:
                  this.loadingLibs = true;
                  this._changeDetectorRef.markForCheck();
                  this.stateChange.emit(renderState);
                  break;
                case PdfTemplateRenderState.RenderingTemplate:
                  this.loadingLibs = false;
                  this.generating = true;
                  this._changeDetectorRef.markForCheck();
                  this.stateChange.emit(renderState);
                  break;
              }
            }
          });
        });
        return forkJoin([...pageTasks]).pipe(
          tap((svgEls: Array<svgjs.Element>) => {
            this.loadingLibs = false;
            this.generating = false;
            this._changeDetectorRef.markForCheck();
            this.stateChange.emit(PdfTemplateRenderState.Done);
          })
        );
      }),
    );
  }

  private setDirty() {
    if (this._currentJob) {
      this._isDirty = true;
      console.log('Pdf template render was scheduled.');
    } else {
      if (this.template) {
        this.isWaitingForTemplate = false;
        console.log('Pdf template render job started.');
        this._currentJob = this.drawTemplate(this.template).pipe(
          tap((svgEls: Array<svgjs.Element>) => {
            console.log('Pdf template render job updated.');
            this._svgEls = svgEls.map(svgEl => svgEl.node as any);
            this.updateView();
          }),
          catchError(err => {
            console.log('Pdf template render job failed.');
            return throwError(err);
          }),
          finalize(() => {
            console.log('Pdf template render job completed.');
            this._currentJob = undefined;
            if (this._isDirty) {
              console.log('Running scheduled Pdf template render job.');
              this._isDirty = false;
              this.setDirty();
            }
          })
        );
        this._currentJob.subscribe();
      } else {
        console.log('Cannot set dirty flag, no template is set.');
        this.isWaitingForTemplate = true;
        this._changeDetectorRef.markForCheck();
      }
    }
  }

  private updateView() {
    if (this.svgInnerContainerEl) {
      this.svgInnerContainerEl.nativeElement.style.width = `${this.zoom * 100}%`;
      this.svgInnerContainerEl.nativeElement.style.height = `${this.zoom * 100}%`;
    }
    // if (this._svgEls) {
    //     this._svgEls.forEach(svgEl => {
    //         svgEl.style.width = `${this.zoom * 100}%`;
    //         svgEl.style.height = `${this.zoom * 100}%`;
    //     });
    // }
  }
}

