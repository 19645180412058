export interface IPDFPageSize {
  id: string;
  title: string;
  width: number;
  height: number;
  unit: string; // DTP unit id
}

export interface IPDFPageOrientation {
  id: string;
  title: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const PDFPageOrientations: Array<IPDFPageOrientation> = [
  {
    id: 'landscape',
    title: 'PDF.Page_Landscape'
  },
  {
    id: 'portrait',
    title: 'PDF.Page_Portrait'
  }
];
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
export const PDFPageSizes: Array<IPDFPageSize> = [
  {
    id: 'a2',
    title: 'A2',
    width: 420,
    height: 594,
    unit: 'mm'
  },
  {
    id: 'a3',
    title: 'A3',
    width: 297,
    height: 420,
    unit: 'mm'
  },
  {
    id: 'a4',
    title: 'A4',
    width: 210,
    height: 297,
    unit: 'mm'
  },
  {
    id: 'a5',
    title: 'A5',
    width: 148,
    height: 210,
    unit: 'mm'
  },
  {
    id: 'governmentLetter',
    title: 'Government-Letter',
    width: 8,
    height: 10.5,
    unit: 'in'
  },
  {
    id: 'juniorLegal',
    title: 'Junior Legal',
    width: 5,
    height: 8,
    unit: 'in'
  },
  {
    id: 'ledger',
    title: 'Ledger / Tabloid',
    width: 11,
    height: 17,
    unit: 'in'
  },
  {
    id: 'legal',
    title: 'Legal',
    width: 8.5,
    height: 14,
    unit: 'in'
  },
  {
    id: 'letter',
    title: 'Letter',
    width: 8.5,
    height: 11,
    unit: 'in'
  },
  {
    id: 'custom',
    title: 'Custom size',
    width: -1,
    height: -1,
    unit: 'mm'
  },
];

export interface IPDFDocumentProperties {
  title?: string;
  subject?: string;
  author?: string;
  keywords?: string;
  creator?: string;
}


export interface IDTPUnit {
  id: string;
  title: string;
  ptConversionFactor: number;
  decimals: number;
}

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const DTPUnits: Array<IDTPUnit> = [
  {
    id: 'pt',
    title: 'PDF.DTPUnit_pt',
    ptConversionFactor: 1,
    decimals: 0
  },
  {
    id: 'mm',
    title: 'PDF.DTPUnit_mm',
    ptConversionFactor: 2.8346438836889,
    decimals: 1
  },
  {
    id: 'in',
    title: 'PDF.DTPUnit_in',
    ptConversionFactor: 72,
    decimals: 2
  }
];
