import { animate, AnimationBuilder, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getCssNumber, Logger, splitToChunks, zipArrays } from '@myia/ngx-core';
import { combineLatest, concat, from, fromEvent, Observable, Observer, of, Subject, Subscription } from 'rxjs';
import { combineLatestAll, debounceTime, distinctUntilChanged, map, mergeMap, tap, throttleTime } from 'rxjs/operators';
import { DTPUnitHelper } from './dtpUnitHelper';
import { IPDFDocumentProperties, IPDFPageOrientation, IPDFPageSize } from './pdfEntities';
import { IPDFTemplate, IPDFTemplatePage } from './pdfTemplateEntities';
import { PdfTemplateRenderService } from './pdfTemplateRenderService';

export enum PdfPreviewRenderState {
  LoadingLibraries,
  GeneratingPdf,
  Saving,
  Done,
  Error
}

@Component({
  selector: 'pdf-preview',
  styleUrls: ['./pdfPreviewComponent.component.scss'],
  template: `
    <!--<object class="pdfPreview" #pdfPreview type="application/pdf">-->
    <!--<p>The PDF couldn't be displayed</p>-->
    <!--</object>-->
    <div class="loading" *ngIf="loadingLibs || isGenerating">
      <progress-indicator-circle indicatorClass="small"></progress-indicator-circle>
      <span>{{(loadingLibs ? 'Loading' : processingText)|trans}}</span>
      <div *ngIf="isGenerating" class="progress">{{progress}} %</div>
    </div>
    <div class="previewContainer" #previewContainer [ngClass]="{show: !isGenerating, moved: offset.x || offset.y}"
         (mouseWheel)="onMouseWheel($event)" (mousePan)="onMousePan($event)">
      <span class="canvasWrapper" #canvasWrapper></span>
    </div>
    <div class="children">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfPreviewComponent implements OnChanges, AfterViewInit, OnDestroy {
  @HostBinding('class') hostClasses = 'pdfPreview';
  @ViewChild('previewContainer', {static: true}) previewContainerEl?: ElementRef;
  @ViewChild('canvasWrapper', {static: true}) canvasWrapperEl?: ElementRef;

  @Input() processingText = '';

  @Input() templates?: Array<IPDFTemplate>;
  @Input() pageSize?: IPDFPageSize;
  @Input() pageOrientation?: IPDFPageOrientation;
  @Input() multipleTemplatesPerPage = false;
  @Input() reverseTemplatesOrderOnBackPage = false;
  @Input() collectEvenPages = false;
  @Input() showCropMarks = false;
  @Input() zoom = 1.0; // 1.0 = 100%
  @Input() offset: { x: number, y: number } = {x: 0, y: 0};
  @Input() cropMarkWeight = 0.25; // default 0.25pt
  @Input() cropMarkSize = 25; // default 25pt
  @Input() cropMarkLineMargin = 9; // 0.125inch

  @Input() documentProperties?: IPDFDocumentProperties;

  @Input() currentPageNo = 1;

  @Output() errorOccurred = new EventEmitter<string>();
  @Output() stateChange = new EventEmitter<PdfPreviewRenderState>();
  @Output() pagesCountChange = new EventEmitter<number>();
  @Output() zoomChange = new EventEmitter<number>();
  @Output() offsetChange = new EventEmitter<{ x: number, y: number }>();
  @Output() currentPageNumberChanged = new EventEmitter<number>();
  @Output() progressChanged = new EventEmitter<number>();

  @HostBinding('class.loading')
  loadingLibs = true;
  @HostBinding('class.generating')
  isGenerating = false;
  @HostBinding('class.saving')
  isSaving = false;

  @Input() maxZoom = 5;
  @Input() minZoom = 1;

  pagesCount?: number;
  progress?: number; // 0 - 100 (%)

  private _mouseWheelObserver?: Observer<void>;
  private _mouseWheelSubscription?: Subscription;

  private _viewInitializing? = new Subject<void>();

  private _doc: any;
  private _pdfDocument: any;

  private _svg2pdf: any;
  private _jsPdf: any;
  private _pdfJSLib: any;

  private _minCropMarkSize = 20;

  private _currentJob?: Subscription;

  private _progress?: number;
  private _pdfPage: any;
  private _sizeChangeSubject = new Subject<void>();
  private _resizeSubscription?: Subscription;

  constructor(private _changeDetectorRef: ChangeDetectorRef, private _logger: Logger, private _pdfTemplateRenderService: PdfTemplateRenderService, private _zone: NgZone, private _animationBuilder: AnimationBuilder) {
    this._zone.runOutsideAngular(() => {
      this._resizeSubscription = fromEvent(window, 'resize').pipe(
        debounceTime(300), // 0.3s
        distinctUntilChanged()
      )
        .subscribe(() => {
            this._zone.run(() => {
              this._sizeChangeSubject.next();
            });
          }
        );
    });
    this._sizeChangeSubject.subscribe(() => {
      this.updatePreviewSize();
    });
  }

  ngAfterViewInit() {
    this._viewInitializing?.next();
    this._viewInitializing?.complete();
    this._viewInitializing = undefined;
    // start processing in next phase to allow rendering loading state before
    setTimeout(() => {
      this.setDirty();
    });
  }

  ngOnDestroy() {
    if (this._mouseWheelSubscription) {
      this._mouseWheelSubscription.unsubscribe();
    }
    this._mouseWheelObserver = undefined;
    if (this._resizeSubscription) {
      this._resizeSubscription.unsubscribe();
    }
    this._pdfPage = null;
    this._pdfDocument = null;
    if (this._currentJob) {
      this._currentJob.unsubscribe();
      this._currentJob = undefined;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._viewInitializing) {
      let isDirty = false;
      let updatePdfView = false;
      let animation = false;
      // eslint-disable-next-line guard-for-in
      for (const changedPropName in changes) {
        switch (changedPropName) {
          case 'currentPageNo':
            updatePdfView = true;
            animation = true;
            break;
          case 'zoom':
            updatePdfView = true;
            break;
          case 'offset':
            this.onMousePan(this.offset);
            break;
          default:
            isDirty = true;
            break;
        }
        if (isDirty) {
          break;
        }
      }
      if (isDirty) {
        this.setDirty();
        return;
      }
      if (updatePdfView) {
        this.updatePdfView(animation).then(() => {
          console.log('PDf viewer updated.');
        });
      }
    }
  }

  onMouseWheel(e: any) {
    if (!this._mouseWheelObserver) {
      this._mouseWheelSubscription =
        new Observable((observer: Observer<any>) => {
          this._mouseWheelObserver = observer;
        }).pipe(
          throttleTime(50, undefined, {
            leading: true,
            trailing: true
          }) // wait 50ms after the last event before emitting last event
        ).subscribe((delta: number) => {
          let newZoom = this.zoom + (delta > 0 ? 0.1 : -0.1);
          if (newZoom > this.maxZoom) {
            newZoom = this.maxZoom;
          }
          if (newZoom < this.minZoom) {
            newZoom = this.minZoom;
          }
          this.zoom = newZoom;
          this.zoomChange.emit(newZoom);
        });
    }

    this._mouseWheelObserver?.next(e);
  }

  onMousePan($event: { x: number; y: number }) {
    this.offset.x += $event.x;
    this.offset.y += $event.y;
    this.offsetChange.emit(this.offset);
    // console.log(`${this.offsetX}, ${this.offsetY}`);
    if (this.canvasWrapperEl) {
      this.canvasWrapperEl.nativeElement.style.transform = `translate(${this.offset.x}px, ${this.offset.y}px)`;
    }
  }

  savePdf(): Observable<Blob | undefined> {
    return new Observable((observer: Observer<Blob | undefined>) => {
      if (this._doc) {
        this.stateChange.emit(PdfPreviewRenderState.Saving);
        setTimeout(() => {
          // doc.output('save', filename) - does not work(bug) - filename is ignored
          const blob = this._doc.output('blob');
          this.stateChange.emit(PdfPreviewRenderState.Done);
          observer.next(blob);
          observer.complete();
        }, 200); // allow UI updated
      } else {
        observer.next(undefined);
        observer.complete();
      }
    });
  }

  updatePreviewSize() {
    if (this._pdfPage) {
      this.renderPageToCanvas(this._pdfPage, false).then(() => {
        console.log('PDF viewer size updated.');
      });
    }
  }

  private drawPage(doc: any, template: IPDFTemplate, templatePage: IPDFTemplatePage, columnNumber: number, rowNumber: number, horSpacing: number, verSpacing: number, cropMarkSize: number) {
    return of(templatePage).pipe(
      // tap(() => {
      //     console.log('startP:' + templatePage.pageNo);
      // }),
      mergeMap(() => {
        const templateWidth = templatePage.width;
        const templateHeight = templatePage.height;
        const templateFullWidth = templateWidth + (2 * (cropMarkSize ? cropMarkSize : this.cropMarkLineMargin));
        const templateFullHeight = templateHeight + (2 * (cropMarkSize ? cropMarkSize : this.cropMarkLineMargin));
        const pageNo = templatePage.pageNo;
        const templateX = horSpacing + cropMarkSize + columnNumber * (templateFullWidth + horSpacing);
        const templateY = verSpacing + cropMarkSize + rowNumber * (templateFullHeight + verSpacing);
        return this._pdfTemplateRenderService.renderTemplatePage(template, pageNo, this.showCropMarks, false, false).pipe(
          mergeMap(svgElObj => {
            const svgEl = svgElObj.node;
            // render the svg element
            const result = this._svg2pdf(svgEl, doc, {
              x: templateX,
              y: templateY,
              scale: 1.0 // 72/96 - ratio of px to pt units
            });
            return from(result).pipe(map(() => svgElObj));
          }),
          tap(svgElObj => {
            // render crop marks
            const svgEl = svgElObj.node;
            if (cropMarkSize && this.showCropMarks) {
              // add crop marks
              const lineThickness = this.cropMarkWeight;
              const lineLength = cropMarkSize;
              doc.setFillColor('#000000');
              // left-top
              doc.rect(templateX - lineLength, templateY - lineThickness / 2 + templatePage.cropSize, lineLength - this.cropMarkLineMargin, lineThickness, 'F');
              doc.rect(templateX + templatePage.cropSize - lineThickness / 2, templateY - lineLength, lineThickness, lineLength - this.cropMarkLineMargin, 'F');
              // right-top
              doc.rect(templateX + templatePage.width + this.cropMarkLineMargin, templateY - lineThickness / 2 + templatePage.cropSize, lineLength - this.cropMarkLineMargin, lineThickness, 'F');
              doc.rect(templateX + templatePage.width - templatePage.cropSize - lineThickness / 2, templateY - lineLength, lineThickness, lineLength - this.cropMarkLineMargin, 'F');
              // left-bottom
              doc.rect(templateX - lineLength, templateY + templatePage.height - lineThickness / 2 - templatePage.cropSize, lineLength - this.cropMarkLineMargin, lineThickness, 'F');
              doc.rect(templateX + templatePage.cropSize - lineThickness / 2, templateY + templatePage.height + this.cropMarkLineMargin, lineThickness, lineLength - this.cropMarkLineMargin, 'F');
              // right-bottom
              doc.rect(templateX + templatePage.width + this.cropMarkLineMargin, templateY - lineThickness / 2 + templatePage.height - templatePage.cropSize, lineLength - this.cropMarkLineMargin, lineThickness, 'F');
              doc.rect(templateX + templatePage.width - templatePage.cropSize - lineThickness / 2, templateY + templatePage.height + this.cropMarkLineMargin, lineThickness, lineLength - this.cropMarkLineMargin, 'F');
            }
            // destroy source svg element
            if (svgEl.parentNode) {
              svgEl.parentNode.removeChild(svgEl);
            }
          }),
          // delay(2500)
        );

      }),
      // tap(() => {
      //     console.log('endP:' + templatePage.pageNo);
      // })
    );
  }


  private drawTemplatePageGroup(doc: any, templatePageGroup: Array<{ template: IPDFTemplate, page: IPDFTemplatePage, placementCount: { columns: number, rows: number } }>, showCropMarks: boolean, pageSizeWidth: number, pageSizeHeight: number) {
    return of(templatePageGroup).pipe(
      tap(() => {
        // console.log('new page!');
        doc.addPage();
        // console.log('start:' + JSON.stringify(pages));
      }),
      // delay(500),
      mergeMap((templates) => {
        let colNumber = 0;
        let rowNumber = 0;
        return concat(...templates.map(td => of(td).pipe(
            mergeMap((t) => {
              const increasePagePos = tap(() => {
                colNumber++;
                if (colNumber >= t.placementCount.columns) {
                  colNumber = 0;
                  rowNumber++;
                }
                if (this._progress == null) {
                  this._progress = 0;
                }
                this._progress++;
                this.setProgress(Math.floor(100 * this._progress / (this.templates ? (this.templates.length * (this.templates[0].pages?.length ?? 0)): 1)));
              });

              if (t.template) { // t.template could be null when empty template added for correct placement of last templates in row on page
                const templateWidth = t.page.width;
                const templateHeight = t.page.height;
                const templatesInRow = t.placementCount.columns;
                const templatesInColumn = t.placementCount.rows;
                const cropMarkSize = this.getCropMarkSize(pageSizeWidth, pageSizeHeight, templateWidth, templateHeight, templatesInRow, templatesInColumn, showCropMarks);
                const templateFullWidth = templateWidth + (2 * (showCropMarks ? cropMarkSize : this.cropMarkLineMargin));
                const templateFullHeight = templateHeight + (2 * (showCropMarks ? cropMarkSize : this.cropMarkLineMargin));
                const horSpacing = Math.max((pageSizeWidth - templateFullWidth * templatesInRow) / (templatesInRow + 1), 0);
                const verSpacing = Math.max((pageSizeHeight - templateFullHeight * templatesInColumn) / (templatesInColumn + 1), 0);
                return this.drawPage(doc, t.template, t.page, colNumber, rowNumber, horSpacing, verSpacing, cropMarkSize).pipe(increasePagePos);
              } else {
                return of(t).pipe(increasePagePos);
              }
            })
          ))
        ).pipe(
          (...args: Array<any>) => combineLatest(args)
        );
      }),
      // tap(() => {
      //     console.log('end!!!');
      // }),
    );
  }

  private renderSvgToPdf(templates: Array<IPDFTemplate>, pageSize: IPDFPageSize | undefined, pageOrientation: IPDFPageOrientation | undefined, showCropMarks: boolean, multipleTemplatesPerPage: boolean, docProperties?: IPDFDocumentProperties): Observable<number | undefined> {
    const unit = this.pageSize?.unit;
    if (!templates || templates.length === 0 || !pageSize || !pageOrientation || !unit) {
      return of(0);
    }
    this._progress = 0;
    this.setProgress(0);
    return ((this._viewInitializing ? this._viewInitializing : of(undefined)) as Observable<void>).pipe(
      // load external libs
      mergeMap(() => {
        return this.loadLibraries();
      }),
      mergeMap(() => {
        this.loadingLibs = false;
        this.isGenerating = true;
        this._changeDetectorRef.markForCheck();
        this.stateChange.emit(PdfPreviewRenderState.GeneratingPdf);
        this.destroyCurrentCanvas();

        const pageSizeWidthInPt = DTPUnitHelper.convertToUnit(pageSize.width, unit, 'pt');
        const pageSizeHeightInPt = DTPUnitHelper.convertToUnit(pageSize.height, unit, 'pt');
        const pageSizeWidth = pageOrientation.id === 'portrait' ? pageSizeWidthInPt : pageSizeHeightInPt;
        const pageSizeHeight = pageOrientation.id === 'portrait' ? pageSizeHeightInPt : pageSizeWidthInPt;

        const doc = new this._jsPdf({
          orientation: pageSizeWidth < pageSizeHeight ? 'portrait' : 'landscape',
          unit: 'pt',
          format: [pageSizeWidth, pageSizeHeight],
          compress: true
        });
        doc.deletePage(1); // created document contains one empty page, delete it.

        if (docProperties) {
          doc.setProperties({
            title: docProperties.title || '',
            subject: docProperties.subject || '',
            author: docProperties.author || '',
            keywords: docProperties.keywords || '',
            creator: docProperties.creator || '',
          });
        }

        const firstTemplate = templates[0];
        const pageLayout = firstTemplate.pages?.map(p => {
          return {
            pageNo: p.pageNo,
            count: multipleTemplatesPerPage ? this.calculatePageLayout(p, pageSizeWidth, pageSizeHeight, showCropMarks) : {
              columns: 1,
              rows: 1
            }
          };
        });
        const pages = firstTemplate.pages as Array<IPDFTemplatePage>;
        const templateGroupsPerPage = zipArrays(...pages.map((page, index) => {
          const templatePlacementCount = pageLayout?.find(t => t.pageNo === page.pageNo)?.count ?? {columns: 1, rows: 1};
          const reverseOrder = this.reverseTemplatesOrderOnBackPage && !!(index % 2); // reverse order in rows for two sided print
          const templatesInfoList = templates.map(template => {
            return {
              template: template as (IPDFTemplate | undefined),
              page: pages[index] as (IPDFTemplatePage | undefined),
              placementCount: templatePlacementCount
            };
          });
          if (reverseOrder) {
            // add empty templates to fill full row (order will be reversed)
            const missingTemplatesInRow = templates.length % templatePlacementCount.columns;
            for (let t = 0; t < missingTemplatesInRow; t++) {
              templatesInfoList.push({
                template: undefined,
                page: undefined,
                placementCount: templatePlacementCount
              });
            }
          }
          return splitToChunks(templatesInfoList, templatePlacementCount.rows * templatePlacementCount.columns, chunk => {
            if (reverseOrder) {
              // reverse order of templates in row on page
              const rowsChunks = splitToChunks(chunk, templatePlacementCount.columns, subChunk => subChunk.reverse());
              // then concatenate back to single array
              return ([] as any[]).concat(...rowsChunks);
            }
            return chunk;
          });
        }));
        return concat(...templateGroupsPerPage.map(templatePageGroup => this.drawTemplatePageGroup(doc, templatePageGroup, showCropMarks, pageSizeWidth, pageSizeHeight))).pipe(
          combineLatestAll(),
          map(() => doc),
          tap(() => {
            console.log('OK');
          }),
        );

        // const firstTemplate = templates[0];
        //
        // return concat(...firstTemplate.pages.map(page => of(page).pipe(
        //         mergeMap((page) => {
        //             pages++;
        //             const pagesCount = doc.internal.getNumberOfPages();
        //             if (pagesCount < pages) {
        //                 doc.addPage(); // create new page in PDF
        //             }
        //
        //             const pageIndex = firstTemplate.pages.indexOf(page);
        //             const templatePage = firstTemplate.pages[pageIndex];
        //
        //             const templateWidth = templatePage.width, templateHeight = templatePage.height;
        //
        //             let templatesInRow = 1;
        //             let templatesInColumn = 1;
        //             if (multipleTemplatesPerPage) {
        //                 templatesInRow = Math.max(Math.floor(pageSizeWidth / templateWidth), 1);
        //                 templatesInColumn = Math.max(Math.floor(pageSizeHeight / templateHeight), 1);
        //                 if (templatesInRow * (templateWidth + (showCropMarks ? this._minCropMarkSize : cropMarkLineMargin)) > pageSizeWidth && templatesInRow > 1) {
        //                     templatesInRow--;
        //                 }
        //                 if (templatesInColumn * (templateHeight + (showCropMarks ? this._minCropMarkSize : cropMarkLineMargin)) > pageSizeHeight && templatesInColumn > 1) {
        //                     templatesInColumn--;
        //                 }
        //
        //                 // place in of page if templates count is smaller than templatesInRow
        //                 if (templatesInRow > templates.length) {
        //                     templatesInRow = templates.length;
        //                 }
        //
        //                 // place in of page if templates count is smaller than templatesInColumn
        //                 if (Math.ceil(templates.length / templatesInRow) < templatesInColumn) {
        //                     templatesInColumn = Math.ceil(templates.length / templatesInRow);
        //                 }
        //             }
        //
        //             const spaceLeftHor = pageSizeWidth - templateWidth * templatesInRow;
        //             const spaceLeftVert = pageSizeHeight - templateHeight * templatesInColumn;
        //             const cropMarkSize = showCropMarks ? Math.max(Math.min(showCropMarks ? this.cropMarkSize : cropMarkLineMargin, Math.min(spaceLeftHor / (templatesInRow + 1), spaceLeftVert / (templatesInColumn + 1))), showCropMarks ? this._minCropMarkSize : cropMarkLineMargin) : cropMarkLineMargin;
        //
        //             const templateFullWidth = templateWidth + (2 * (showCropMarks ? cropMarkSize : cropMarkLineMargin));
        //             const templateFullHeight = templateHeight + (2 * (showCropMarks ? cropMarkSize : cropMarkLineMargin));
        //             const horSpacing = Math.max((pageSizeWidth - templateFullWidth * templatesInRow) / (templatesInRow + 1), 0);
        //             const verSpacing = Math.max((pageSizeHeight - templateFullHeight * templatesInColumn) / (templatesInColumn + 1), 0);
        //
        //             let x = 0, y = 0, cnt = 0;
        //             const moveToNextPosition = () => {
        //                 x++;
        //                 cnt++;
        //                 if (x === templatesInRow) {
        //                     x = 0;
        //                     y++;
        //                     if (y === templatesInColumn) {
        //                         y = 0;
        //                         if (cnt < templates.length) { // avoid creation of last empty page
        //                             doc.addPage(); // create new page in PDF
        //                         }
        //                     }
        //                 }
        //             };
        //             let progress = 0;
        //             return concat(...templates.map(template => {
        //                 const templatePage = template.pages[pageIndex];
        //                 const pageNo = templatePage.pageNo;
        //                 return this._pdfTemplateRenderService.renderTemplatePage(template, pageNo, this.showCropMarks, false, false).pipe(
        //                     tap(svgElObj => {
        //                         const svgEl = svgElObj.node;
        //                         const templateX = horSpacing + cropMarkSize + x * (templateFullWidth + horSpacing);
        //                         const templateY = verSpacing + cropMarkSize + y * (templateFullHeight + verSpacing);
        //                         // render the svg element
        //                         this._svg2pdf(svgEl, doc, {
        //                             xOffset: templateX,
        //                             yOffset: templateY,
        //                             scale: 1.0 // 72/96 - ratio of px to pt units
        //                         });
        //
        //                         if (showCropMarks) {
        //                             // add crop marks
        //                             const lineThickness = 0.25; // 0.25pt
        //                             const lineLength = cropMarkSize;
        //                             doc.setFillColor('#000000');
        //                             // left-top
        //                             doc.rect(templateX - lineLength, templateY - lineThickness / 2 + templatePage.cropSize, lineLength - cropMarkLineMargin, lineThickness, 'F');
        //                             doc.rect(templateX + templatePage.cropSize - lineThickness / 2, templateY - lineLength, lineThickness, lineLength - cropMarkLineMargin, 'F');
        //                             // right-top
        //                             doc.rect(templateX + templatePage.width + cropMarkLineMargin, templateY - lineThickness / 2 + templatePage.cropSize, lineLength - cropMarkLineMargin, lineThickness, 'F');
        //                             doc.rect(templateX + templatePage.width - templatePage.cropSize - lineThickness / 2, templateY - lineLength, lineThickness, lineLength - cropMarkLineMargin, 'F');
        //                             // left-bottom
        //                             doc.rect(templateX - lineLength, templateY + templatePage.height - lineThickness / 2 - templatePage.cropSize, lineLength - cropMarkLineMargin, lineThickness, 'F');
        //                             doc.rect(templateX + templatePage.cropSize - lineThickness / 2, templateY + templatePage.height + cropMarkLineMargin, lineThickness, lineLength - cropMarkLineMargin, 'F');
        //                             // right-bottom
        //                             doc.rect(templateX + templatePage.width + cropMarkLineMargin, templateY - lineThickness / 2 + templatePage.height - templatePage.cropSize, lineLength - cropMarkLineMargin, lineThickness, 'F');
        //                             doc.rect(templateX + templatePage.width - templatePage.cropSize - lineThickness / 2, templateY + templatePage.height + cropMarkLineMargin, lineThickness, lineLength - cropMarkLineMargin, 'F');
        //                         }
        //                         // destroy source svg element
        //                         if (svgEl.parentNode) {
        //                             svgEl.parentNode.removeChild(svgEl);
        //                         }
        //
        //                         moveToNextPosition();
        //                         progress++;
        //                         this.setProgress(Math.floor(100 * progress / templates.length));
        //                     }),
        //                     map(() => of(null)) // return observable to be able to combineAll results
        //                 );
        //             })).pipe(
        //                 combineAll(),
        //                 map(() => {
        //                     return of(doc);
        //                 })
        //             );
        //         })
        //     ))
        // ).pipe(
        //     combineAll(),
        //     map(() => {
        //         return doc;
        //     })
        // );
      }),
      mergeMap(doc => {
        // generate PDf output
        this._doc = doc;
        const pagesCount = doc.getNumberOfPages();
        this.pagesCount = pagesCount;
        if (this.currentPageNo > pagesCount) {
          this.setPageNo(pagesCount);
        }
        let pdfDocumentUrl;
        try {
          pdfDocumentUrl = doc.output('datauristring');
        } catch (err) {
          console.log(`SVG2PDF error: ${err}`);
          this.stateChange.emit(PdfPreviewRenderState.Error);
          throw err;
        }
        // show in embedded native PDF viewer
        // this.pdfPreviewEl.nativeElement.data = pdfDocumentUrl;
        if (pdfDocumentUrl) {
          // show in PDF.js viewer
          // Loading a document.
          const loadingTask = this._pdfJSLib.getDocument(pdfDocumentUrl);
          const loadingPromise = loadingTask.promise.then((pdfDocument: any) => {
            this._pdfDocument = pdfDocument;
            return this.updatePdfView(false);
          }).catch((reason: any) => {
            console.error('Error: ' + reason);
            this.isGenerating = false;
            this._changeDetectorRef.markForCheck();
            this.errorOccurred.emit(reason);
            this.stateChange.emit(PdfPreviewRenderState.Error);
          });
          return from(loadingPromise).pipe(
            map(() => {
              return this.pagesCount;
            })
          );
        } else {
          console.log('No input PDF!');
          this.isGenerating = false;
          this._changeDetectorRef.markForCheck();
          this.stateChange.emit(PdfPreviewRenderState.Done);
          return of(0);
        }
      })
    );
  }

  private renderPageToCanvas(pdfPage: any, animation: boolean) {
    this.destroyCurrentCanvas(animation);

    const vSize = this.getViewportSize();
    // create new canvas
    const canvas = document.createElement('canvas');
    this.canvasWrapperEl?.nativeElement.appendChild(canvas);

    // Display page on the existing canvas with 100% scale.
    let pdfViewport = pdfPage.getViewport({scale: 1.0});
    const scaleToFit = Math.min(vSize.width / pdfViewport.width, vSize.height / pdfViewport.height);
    pdfViewport = pdfPage.getViewport({scale: scaleToFit * this.zoom});
    canvas.width = pdfViewport.width;
    canvas.height = pdfViewport.height;
    // console.log(`c: ${canvas.width}, ${canvas.height} - scale: ${scaleToFit} - ${pdfViewport.width}, ${pdfViewport.height}`);
    const ctx = canvas.getContext('2d');
    const renderTask = pdfPage.render({
      canvasContext: ctx,
      viewport: pdfViewport
    });
    renderTask.promise.then(() => {
      this.isGenerating = false;
      this._changeDetectorRef.markForCheck();
      this.stateChange.emit(PdfPreviewRenderState.Done);
    }, (err: any) => {
      this.errorOccurred.emit(err);
      this.isGenerating = false;
      this._changeDetectorRef.markForCheck();
      this.stateChange.emit(PdfPreviewRenderState.Error);
    });
    return renderTask.promise;
  }

  private loadLibraries(): Observable<void> {
    if (this._svg2pdf) {
      // already loaded
      return of(undefined);
    }
    this.stateChange.emit(PdfPreviewRenderState.LoadingLibraries);
    this.loadingLibs = true;
    this._changeDetectorRef.markForCheck();
    return new Observable((observer: Observer<void>) => {
      // @ts-ignore
      Promise.all([import('svg2pdf.js'), import('jspdf'), import('pdfjs-dist')]).then(([{svg2pdf}, {default: jspdfLib}, pdfJsDistLib]) => {
        // svg -> pdf
        this._svg2pdf = svg2pdf;
        this._jsPdf = jspdfLib;
        // pdf viewer
        this._pdfJSLib = pdfJsDistLib;
        this._pdfJSLib.GlobalWorkerOptions.workerSrc = '/pdfjs-dist/pdf.worker.min.js'; // Setting worker path to worker bundle.

        observer.next(undefined);
        observer.complete();
      });
    });
  }

  private setDirty() {
    if (this._currentJob) {
      console.log('Current Pdf render job canceled.');
      this._currentJob.unsubscribe();
    }
    if (this.templates) {
      console.log('Pdf render job started.');
      const renderJob = this.renderSvgToPdf(this.templates, this.pageSize, this.pageOrientation, this.showCropMarks, this.multipleTemplatesPerPage, this.documentProperties);
      this._currentJob = renderJob.subscribe({
        next: (pagesCount) => {
          // if (this.pagesCount !== pagesCount) {
          this.pagesCount = pagesCount;
          this._changeDetectorRef.markForCheck();
          this.pagesCountChange.emit(pagesCount);
          // }
        },
        error: undefined,
        complete: () => {
          console.log('Pdf render job completed.');
          this._currentJob = undefined;
        }
      });
    }
  }

  private getViewportSize() {
    const previewContainerEl = this.previewContainerEl?.nativeElement as HTMLElement;
    const viewportWidth = previewContainerEl.offsetWidth - (getCssNumber(previewContainerEl, 'padding-left') + getCssNumber(previewContainerEl, 'padding-right'));
    const viewportHeight = previewContainerEl.offsetHeight - (getCssNumber(previewContainerEl, 'padding-top') + getCssNumber(previewContainerEl, 'padding-bottom'));
    return {
      width: viewportWidth,
      height: viewportHeight
    };
  }

  private updatePdfView(animation: boolean) {
    // Request a page
    return this._pdfDocument.getPage(this.currentPageNo).then((pdfPage: any) => {
      this._pdfPage = pdfPage;
      return this.renderPageToCanvas(pdfPage, animation);
    });
  }

  private setPageNo(pageNo: number) {
    this.currentPageNo = pageNo;
    this.currentPageNumberChanged.emit(this.currentPageNo);
  }

  private destroyCurrentCanvas(withAnimation?: boolean) {
    // destroy current Canvas
    if (withAnimation) {
      let currentCanvas: HTMLCanvasElement | undefined;
      for (const canvas of this.canvasWrapperEl?.nativeElement.children) {
        if (!canvas.classList.contains('removing')) {
          currentCanvas = canvas;
          break;
        }
      }
      if (currentCanvas) {
        console.log('Removing PDf Preview canvas with animation.');
        currentCanvas.classList.add('removing');
        const animationFactory = this._animationBuilder.build([
          style({opacity: 1, zIndex: 2}),
          animate(200, style({opacity: 0}))
        ]);
        const animPlayer = animationFactory.create(currentCanvas);
        animPlayer.onDone(() => {
          console.log('PDf Preview canvas animation completed.');
          if (currentCanvas?.parentElement) {
            currentCanvas.parentElement.removeChild(currentCanvas);
            console.log('PDf Preview canvas removed.');
          }
          animPlayer.destroy();
        });
        animPlayer.play();
      } else {
        console.log('No PDf Preview canvas found.');
      }
    } else {
      while (this.canvasWrapperEl?.nativeElement.firstChild) {
        this.canvasWrapperEl.nativeElement.removeChild(this.canvasWrapperEl.nativeElement.firstChild);
        console.log('PDf Preview canvas removed (no anim).');
      }
    }
  }

  private setProgress(number: number) {
    this.progress = number;
    this.progressChanged.emit(number);
    this._changeDetectorRef.markForCheck();
  }

  private calculatePageLayout(page: IPDFTemplatePage, pageSizeWidth: number, pageSizeHeight: number, showCropMarks: boolean): { columns: number, rows: number } {
    const templateWidth = page.width;
    const templateHeight = page.height;
    const margin = 2 * (showCropMarks ? this.cropMarkSize : this.cropMarkLineMargin);
    const templatesInRow = Math.max(Math.floor(pageSizeWidth / (templateWidth + margin)), 1);
    const templatesInColumn = Math.max(Math.floor(pageSizeHeight / (templateHeight + margin)), 1);
    return {
      rows: templatesInColumn,
      columns: templatesInRow
    };
  }

  private getCropMarkSize(pageSizeWidth: number, pageSizeHeight: number, templateWidth: number, templateHeight: number, templatesInRow: number, templatesInColumn: number, showCropMarks: boolean) {
    const spaceLeftHor = pageSizeWidth - templateWidth * templatesInRow;
    const spaceLeftVert = pageSizeHeight - templateHeight * templatesInColumn;
    return showCropMarks ? Math.max(Math.min(showCropMarks ? this.cropMarkSize : this.cropMarkLineMargin, Math.min(spaceLeftHor / (templatesInRow + 1), spaceLeftVert / (templatesInColumn + 1))), showCropMarks ? this._minCropMarkSize : this.cropMarkLineMargin) : this.cropMarkLineMargin;
  }
}

