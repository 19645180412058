import { Observable, Observer } from 'rxjs';

// import little loader library
import load from 'little-loader';
import { Injectable } from '@angular/core';
import { addListener, Logger, removeAllListeners } from '@myia/ngx-core';

const elementCheckInterval = 500;

@Injectable({providedIn: 'root'})
export class LittleLoader {
  load = load;

  constructor(private _logger: Logger) {
  }

  waitForElement(sourceElementId: string, timeout?: number): Observable<boolean> {
    let elementCheckTimeout: any;
    let waitTimeout: any;
    return new Observable((observer: Observer<boolean>) => {
      let closed = false;
      if (timeout) {
        waitTimeout = setTimeout(() => {
          waitTimeout = null;
          if (elementCheckTimeout) {
            clearTimeout(elementCheckTimeout);
          }
          closed = true;
          observer.next(false);
          observer.complete();
        }, timeout);
      }
      const check = () => {
        elementCheckTimeout = setTimeout(() => {
          this._logger.log('Checking element:' + sourceElementId);
          const iframeEl = document.getElementById(sourceElementId);
          if (iframeEl) {
            addListener(iframeEl, ['load'], () => {
              this._logger.log('iframe loaded');
              removeAllListeners(iframeEl, ['load']);
              // element found
              if (waitTimeout) {
                clearTimeout(waitTimeout);
              }
              closed = true;
              observer.next(true);
              observer.complete();
            });
            return;
          }
          if (!closed) {
            check();
          }
        }, elementCheckInterval);
      };
      check();
    });
  }
}
