import { NgModule, ModuleWithProviders } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    CoreModule
  ]
})
export class QrCodeReaderModule {
  static forRoot(): ModuleWithProviders<QrCodeReaderModule> {
    return {
      ngModule: QrCodeReaderModule
    };
  }
}
