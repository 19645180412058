import { InjectionToken, Provider } from '@angular/core';

export interface ISignalRModuleConfig {
  config?: Provider;
}

export interface ISignalRConfig {
  signalRUrl: string;
}

export const SIGNAL_R_CONFIGURATION = new InjectionToken<ISignalRModuleConfig>('SIGNAL_R_CONFIGURATION');


