import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CoreModule } from '@myia/ngx-core';
import { ISignalRModuleConfig } from './signalRConfig';

@NgModule({
    imports: [
        CoreModule
    ],
    declarations: [
    ],
    exports: [
    ]
})
export class SignalRModule {
    static forRoot(signalRModuleConfig: ISignalRModuleConfig): ModuleWithProviders<SignalRModule> {
        return {
            ngModule: SignalRModule,
            providers: [
                signalRModuleConfig.config,
            ] as Provider[]
        };
    }
}
