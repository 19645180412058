import { QRCodeModel } from './qrCodeModel';

export class SVGDrawing {
  private readonly _el: any;
  private readonly _htOption: any;

  constructor(el: any, htOption: any) {
    this._el = el;
    this._htOption = htOption;
  }

  draw(oQRCode: QRCodeModel) {
    const _htOption = this._htOption;
    const _el = this._el;
    const nCount = oQRCode.getModuleCount();
    const nWidth = Math.floor(_htOption.width / nCount);
    const nHeight = Math.floor(_htOption.height / nCount);
    const overflow = _htOption.withOverflow ? 0.02 : 0;

    this.clear();

    function makeSVG(tag: any, attrs: any) {
      const el = document.createElementNS('http://www.w3.org/2000/svg', tag);
      for (const k in attrs) {
        if (attrs.hasOwnProperty(k)) {
          el.setAttribute(k, attrs[k]);
        }
      }
      return el;
    }

    const svg = makeSVG('svg', {
      viewBox: '0 0 ' + String(nCount + 2 * _htOption.border) + ' ' + String(nCount + 2 * _htOption.border),
      width: _htOption.fixedSize ? String(_htOption.width) : '100%',
      height: _htOption.fixedSize ? String(_htOption.height) : '100%',
      fill: _htOption.colorLight
    });
    svg.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
    _el.appendChild(svg);

    svg.appendChild(makeSVG('rect', {fill: _htOption.colorLight, width: _htOption.fixedSize ? String(nCount + 2 * _htOption.border) : '100%', height: _htOption.fixedSize ? String(nCount + 2 * _htOption.border) : '100%'}));
    svg.appendChild(makeSVG('rect', {
      fill: _htOption.colorDark,
      x: String(_htOption.border),
      y: String(_htOption.border),
      width: '1',
      height: '1',
      id: 'template'
    }));

    for (let row = 0; row < nCount; row++) {
      for (let col = 0; col < nCount; col++) {
        if (oQRCode.isDark(row, col)) {
          if (!_htOption.avoidRefs) {
            const child = makeSVG('use', {x: String(col), y: String(row)});
            child.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#template');
            svg.appendChild(child);
          } else {
            svg.appendChild(makeSVG('rect', {
              fill: _htOption.colorDark,
              x: String(_htOption.border + col - overflow),
              y: String(_htOption.border + row - overflow),
              width: String(1 + 2 * overflow),
              height: String(1 + 2 * overflow)
            }));
          }
        }
      }
    }
  }

  clear() {
    while (this._el.hasChildNodes()) {
      this._el.removeChild(this._el.lastChild);
    }
  }
}
