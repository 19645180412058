import { Pipe, PipeTransform } from '@angular/core';
import { DTPUnitHelper } from './dtpUnitHelper';
import { IDTPUnit } from './pdfEntities';

@Pipe({
    name: 'dtpUnit'
})
export class DtpUnitPipe implements PipeTransform {
    transform(value: number, valueUnitId: string, unit: IDTPUnit): number {
        const convertedValue = DTPUnitHelper.convertToUnit(value, valueUnitId, unit.id);
        if (convertedValue) {
            const roundFact = Math.pow(10, unit.decimals || 0);
            return Math.round(convertedValue * roundFact) / roundFact;
        }
        return convertedValue;
    }
}
